import { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { withTranslation, TFunction } from 'react-i18next';
import { Button } from '../../common/Button';
import Container from '../../common/Container';
import {
  HeaderSection,
  AppTitleContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  AppTitle,
  DropdownMenu,
  DropdownItem,
} from './styles';
import { NavLink, Link, useLocation } from 'react-router-dom';

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();

  const showFeaturesIcon = location.pathname === '/' || location.pathname === '/home';
  const showBuyButton = location.pathname !== '/buy';

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: 'smooth',
      });
      setVisibility(false);
      setDropdownVisible(false);
    };
    return (
      <>
        {showFeaturesIcon && (
          <CustomNavLinkSmall onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Span>{t('Features')}</Span>
            {dropdownVisible ? (
              <DropdownMenu onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <DropdownItem onClick={() => scrollTo('API')}>{t('API')}</DropdownItem>
                <DropdownItem onClick={() => scrollTo('SQL')}>{t('SQL')}</DropdownItem>
                <DropdownItem onClick={() => scrollTo('NoSQL')}>{t('NoSQL')}</DropdownItem>
                <DropdownItem onClick={() => scrollTo('Messaging')}>{t('Messaging')}</DropdownItem>
              </DropdownMenu>
            ) : null}
          </CustomNavLinkSmall>
        )}

        <CustomNavLinkSmall>
          <Span>
            <NavLink to="/roadmap">{t('Roadmap')}</NavLink>
          </Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall>
          <Span>
            <NavLink to="/libraries">{t('Libraries')}</NavLink>
          </Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall>
          <Span>
            <NavLink to="/faq">{t('FAQ')}</NavLink>
          </Span>
        </CustomNavLinkSmall>

        {showBuyButton && <Checkout t={t} />}
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <AppTitleContainer to="/">
            <AppTitle>Asapi</AppTitle>
          </AppTitleContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

function Checkout({ t }: { t: TFunction }) {
  return (
    <CustomNavLinkSmall style={{ width: '180px' }}>
      <Span>
        <Button>
          <a
            style={{ color: 'white', display: 'block', width: '100%', height: '100%' }}
            href="https://codeberg.org/fernandolopez/asapi"
          >
            {t('Download')}
          </a>
        </Button>
      </Span>
    </CustomNavLinkSmall>
  );
}

export default withTranslation()(Header);
