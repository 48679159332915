import { Row, Col } from 'antd';
import { withTranslation, TFunction } from 'react-i18next';
import { SvgIcon } from '../../common/SvgIcon';
import Container from '../../common/Container';

import i18n from 'i18next';
import {
  FooterSection,
  Title,
  NavLink,
  Para,
  Large,
  Chat,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from './styles';

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" key={src} aria-label={src}>
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={10} xs={10}>
              <NavLink to="">
                <Title>{t('Home')}</Title>
              </NavLink>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',

                  justifyContent: 'left',
                }}
              >
                <NavLink to="/legal-advice">
                  <span>{t('Legal Advice')}</span>
                </NavLink>
                <NavLink to="/privacy-policy">
                  <span>{t('Privacy Policy')}</span>
                </NavLink>

              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  justifyContent: 'left',
                }}
              >
                <NavLink to="/user-agreement">
                  <span>{t('User Agreement')}</span>
                </NavLink>
                                <NavLink to="/cookie-policy">
                  <span>{t('Cookie Policy')}</span>
                </NavLink>
              </div>
              <a
                style={{
                  display: 'block',
                  fontSize: '1rem',
                  marginBottom: '0.625rem',
                  transition: 'all 0.2s ease-in-out',
                }}
                href="https://qoback.es"
              >
                {t('Qoback')}
              </a>
            </Col>
            <Col lg={8} md={8} sm={10} xs={10}>
              <Language>{t('Contact')}</Language>
              <Large to="/">{t('Tell us everything')}</Large>
              <Para>{t('Do you have any question? Feel free to reach out.')}</Para>
              <a href="mailto:asapi.info@qoback.es">
                <Chat>{t(`Mail me`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={10} xs={10}>
              <Title>{t('Theme')}</Title>
              <a href="https://github.com/Adrinlol/landy-react-template">
                {t('Made with the awesome')} <strong>{t('Landy React Template')}</strong>
              </a>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={10} xs={10}></Col>
            <Col lg={8} md={8} sm={10} xs={10}>
              <Label htmlFor="select-lang">{t('Language')}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange('en')}>
                  <SvgIcon
                    src="svg/united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange('es')}>
                  <SvgIcon src="svg/spain.svg" aria-label="homepage" width="30px" height="30px" />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      {/* <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <AppTitle>Asapi</AppTitle>
              </LogoContainer>
            </NavLink>
          </Row>
        </Container>
      </Extra> */}
    </>
  );
};

export default withTranslation()(Footer);
